import { defineComponent } from 'vue';
import _ from 'lodash';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import ContactBooksSchemaEditorItemPropertiesItem from './ContactBooksSchemaEditorItemPropertiesItem.vue';
import { ArrayOfObjectPropertyTypes } from '@/constants';
import { convertPropertiesToArray, convertArrayToProperties } from '@/utils';
import { useLocalState } from '@/use';
export default defineComponent({
    name: 'ContactBooksSchemaEditorItemProperties',
    components: {
        OrButton,
        ContactBooksSchemaEditorItemPropertiesItem,
    },
    props: {
        properties: {
            type: Object,
            required: false,
            default: () => { },
        },
        undeletableProperties: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['update'],
    setup(props) {
        const { localState, reset, replace, isChanged, } = useLocalState(convertPropertiesToArray(props.properties));
        return {
            localState,
            reset,
            replaceLocalState: replace,
            isChanged,
        };
    },
    computed: {
        schemaToOutput() {
            return convertArrayToProperties(this.localState);
        },
        hasUnsaved() {
            return _.some(this.localState, ({ isEditable = false }) => isEditable);
        },
        allPropertiesNames() {
            return _.map(this.localState, property => property.key);
        },
    },
    watch: {
        properties: {
            handler(newVal) {
                const newArr = convertPropertiesToArray(newVal);
                const emptyProperty = _.find(newArr, { key: '' });
                if (emptyProperty)
                    emptyProperty.isEditable = true;
                this.replaceLocalState(newArr);
            },
            deep: true,
        },
    },
    methods: {
        getOtherProperties(propertyKey) {
            if (_.isEmpty(propertyKey))
                return this.allPropertiesNames;
            return _.filter(this.allPropertiesNames, _.negate(_.matches(propertyKey)));
        },
        handleAddProperty() {
            this.localState.push({
                type: ArrayOfObjectPropertyTypes.STRING,
                key: '',
                isEditable: true,
            });
        },
        handleDelete(index) {
            this.localState.splice(index, 1);
            this.handleApply();
        },
        handleSave(index, value) {
            this.localState[index] = value;
            this.handleApply();
        },
        handleApply() {
            this.$emit('update', this.schemaToOutput);
        },
    },
});
