import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "loader-container flex flex-col items-center content-center justify-around h-full"
};
const _hoisted_2 = {
    key: 1,
    class: "h-full w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_or_loader = _resolveComponent("or-loader");
    const _component_router_view = _resolveComponent("router-view");
    return (_ctx.migrationsStatus !== _ctx.MigrationsStatus.RESOLVED)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_or_loader, {
                class: "w-[432px]",
                variant: _ctx.LoaderVariant.Linear,
                title: _ctx.$t('contactBooks.loadingContacts'),
                description: _ctx.getDescription
            }, null, 8, ["variant", "title", "description"])
        ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_router_view)
        ]));
}
