import _ from 'lodash';
import { defineStore } from 'pinia';
import { ContactsApi, ContactsSettingsApi, HitlApi } from '@/api';
import { MigrationsStatus } from '@/constants';
import { TranslatableError } from '@/errors';
import { getStoreIdWithoutVersion } from '@/utils';
export const useContactsBooksStore = defineStore('contactBooksV1', {
    state: () => ({
        isInstalled: true,
        migrationsStatus: MigrationsStatus.UNDEFINED,
        total: null,
        items: [],
        schemaPresets: {},
        triggers: [],
    }),
    getters: {
        getBookByName: (state) => {
            return (name) => {
                return _.find(state.items, { name });
            };
        },
        getAccessPermissionsByBookName: (state) => {
            return (name) => {
                var _a;
                return (_a = _.find(state.items, { name })) === null || _a === void 0 ? void 0 : _a.accessPermissions;
            };
        },
        groupMapping: ({ schemaPresets }) => {
            return _.reduce(schemaPresets, (acc, item, key) => {
                return {
                    ...acc,
                    [key]: Object.keys(item.fields),
                };
            }, {});
        },
        defaultSchema: ({ schemaPresets }) => {
            return _.reduce(schemaPresets, (acc, item) => {
                return {
                    ...acc,
                    ...item.fields,
                };
            }, {});
        },
        requiredFields() {
            return Object.keys(this.defaultSchema);
        },
        requiredFieldsProperties() {
            return _.omitBy(_.mapValues(this.defaultSchema, item => _.keys(item.properties)), _.isEmpty);
        },
    },
    actions: {
        async checkIfMigrationsNeeded() {
            try {
                const { checkContactsInstalled } = await ContactsApi.checkContactsInstalled();
                this.isInstalled = checkContactsInstalled;
            }
            catch (e) {
                throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.checkContactsInstalled`, e);
            }
            if (this.isInstalled) {
                const { checkMigrationsNeeded } = await ContactsApi.checkIfMigrationsNeeded();
                this.migrationsStatus = checkMigrationsNeeded ? MigrationsStatus.IN_PENDING : MigrationsStatus.RESOLVED;
            }
            else {
                this.migrationsStatus = MigrationsStatus.IN_PENDING;
            }
        },
        async migrateContactsTables() {
            if (!this.isInstalled) {
                try {
                    await ContactsSettingsApi.createContactsTables();
                    this.isInstalled = true;
                }
                catch (e) {
                    throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.createContactsTables`, e);
                }
            }
            await ContactsSettingsApi.runContactsServiceTablesMigration();
            this.migrationsStatus = MigrationsStatus.RESOLVED;
            await this.getContactBooks();
        },
        async getContactBooks() {
            const resp = await ContactsSettingsApi.getContactBooksList({
                projection: [],
                v: 2,
                includeCount: true,
            });
            this.total = resp.total;
            this.items = resp.data;
        },
        async addContactBook(payload) {
            try {
                await ContactsSettingsApi.createContactBook({
                    ...payload,
                    schema: payload.schema,
                    source: {
                        'tableName': payload.tableName,
                    },
                });
            }
            catch (e) {
                // eslint-disable-next-line
                if ('cause' in e && _.isFunction(e.cause)) {
                    // eslint-disable-next-line
                    const cause = e.cause();
                    const errorMessage = _.get(cause, 'response.data.error');
                    if (typeof errorMessage === 'string') {
                        if (errorMessage.includes('book with this name already exist'))
                            // eslint-disable-next-line max-len
                            throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.bookWithThisNameAlreadyExists`, e);
                    }
                }
                throw e;
            }
            await this.getContactBooks();
        },
        async deleteContactBook({ name }) {
            await ContactsSettingsApi.deleteContactBook({
                name,
            });
            await this.getContactBooks();
        },
        async getContactBook(name) {
            const data = await ContactsSettingsApi.getContactBook({
                name,
                includeCount: true,
            });
            const index = _.findIndex(this.items, { name: data.name });
            if (index > -1) {
                this.items[index] = data;
            }
            else {
                this.items.push(data);
            }
        },
        async updateContactBook(book) {
            await ContactsSettingsApi.updateContactBook(book);
            await this.getContactBook(book.name);
        },
        async getBookFields() {
            this.schemaPresets = await ContactsSettingsApi.getDefaultFields();
        },
        async getTriggers() {
            this.triggers = await HitlApi.sessions.getSessionInitTriggers();
        },
        async shareCrossAccountContactBook(dto) {
            try {
                await ContactsSettingsApi.shareCrossAccountContactBook(dto);
            }
            catch (e) {
                // eslint-disable-next-line
                if ('cause' in e && _.isFunction(e.cause)) {
                    // eslint-disable-next-line
                    const cause = e.cause();
                    const errorMessage = _.get(cause, 'response.data.error');
                    if (typeof errorMessage === 'string') {
                        if (errorMessage.includes('index_not_found_exception'))
                            // eslint-disable-next-line max-len
                            throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.accountNotFound`, e);
                        if (errorMessage.includes('book with this name already exist'))
                            // eslint-disable-next-line max-len
                            throw new TranslatableError(`errors.${getStoreIdWithoutVersion(this.$id)}.bookWithThisNameAlreadyExists`, e);
                    }
                }
                throw e;
            }
            finally {
                await this.getContactBook(dto.name);
            }
        },
        async approveSharingUsersList(dto) {
            await ContactsSettingsApi.approveSharingUsersList(dto);
            await this.getContactBook(dto.name);
        },
        async stopSharingContactBook(dto) {
            await ContactsSettingsApi.stopSharingContactBook(dto);
            await this.getContactBook(dto.name);
        },
    },
});
export default useContactsBooksStore;
