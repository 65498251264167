import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _a;
    const _component_or_icon_button = _resolveComponent("or-icon-button");
    const _component_or_menu_item = _resolveComponent("or-menu-item");
    const _component_or_menu = _resolveComponent("or-menu");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_or_icon_button, {
            ref: "buttonRef",
            icon: "upload",
            tooltip: _ctx.$t('contacts.exportV2'),
            disabled: _ctx.isDisabled,
            size: "l",
            onClick: _cache[0] || (_cache[0] = ($event) => { var _a; return ((_a = _ctx.menuRef) === null || _a === void 0 ? void 0 : _a.toggle()); })
        }, null, 8, ["tooltip", "disabled"]),
        _createVNode(_component_or_menu, {
            ref: "menuRef",
            trigger: (_a = _ctx.buttonRef) === null || _a === void 0 ? void 0 : _a.root
        }, {
            default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
                    return (_openBlock(), _createBlock(_component_or_menu_item, {
                        key: item.label,
                        onClick: item.handler
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                        ]),
                        _: 2
                    }, 1032, ["onClick"]));
                }), 128))
            ]),
            _: 1
        }, 8, ["trigger"])
    ], 64));
}
