import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = (n) => (_pushScopeId("data-v-3fe07bd2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "properties" };
const _hoisted_2 = { class: "title" };
const _hoisted_3 = {
    key: 0,
    class: "rows"
};
const _hoisted_4 = { class: "actions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_contact_books_schema_editor_item_properties_item = _resolveComponent("contact-books-schema-editor-item-properties-item");
    const _component_or_button = _resolveComponent("or-button");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('contactBooks.schemaEditor.properties')), 1),
        (_ctx.localState.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localState, (property, index) => {
                    var _a;
                    return (_openBlock(), _createBlock(_component_contact_books_schema_editor_item_properties_item, {
                        key: property.key,
                        property: property,
                        "other-properties": _ctx.getOtherProperties(property.key),
                        "is-deletable": !((_a = _ctx.undeletableProperties) === null || _a === void 0 ? void 0 : _a.includes(property.key)),
                        onDelete: ($event) => (_ctx.handleDelete(index)),
                        onSave: ($event) => (_ctx.handleSave(index, $event))
                    }, null, 8, ["property", "other-properties", "is-deletable", "onDelete", "onSave"]));
                }), 128))
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_or_button, {
                class: "add-property",
                "data-test": "schema-editor-add-property-button",
                type: "text",
                "is-disabled": _ctx.hasUnsaved,
                content: _ctx.$t('contactBooks.schemaEditor.addProperty'),
                icon: "add",
                "icon-position": "left",
                onClick: _ctx.handleAddProperty
            }, null, 8, ["is-disabled", "content", "onClick"])
        ])
    ]));
}
