import { piniaAuthRouterMiddleware } from '@onereach/auth-ui-module';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import config from '@/config';
import i18n from '@/i18n';
import { useAuth } from '@/stores';
import routes from './routes';
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach(async (to) => {
    await piniaAuthRouterMiddleware(to, useAuth(), config.AUTH_UI_URL);
});
router.afterEach((to) => {
    // See https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(async () => {
        var _a, _b, _c;
        document.title = (_c = await ((_b = (_a = to.meta) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.call(to))) !== null && _c !== void 0 ? _c : i18n.global.t('titles.contacts');
    });
});
export default router;
