import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "app-page flex flex-col h-full bg-surface-1 dark:bg-surface-1-dark" };
const _hoisted_2 = {
    id: "page-content-container",
    class: "relative grow-1 min-h-[0] h-full w-full"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_auth_popover = _resolveComponent("auth-popover");
    const _component_service_navigation_header = _resolveComponent("service-navigation-header");
    const _component_OrNotificationV3 = _resolveComponent("OrNotificationV3");
    const _component_OrToastContainerV3 = _resolveComponent("OrToastContainerV3");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_service_navigation_header, {
            config: _ctx.config,
            "service-translations": _ctx.serviceTranslations,
            token: _ctx.reactiveToken,
            "account-id": _ctx.accountId,
            "breadcrumb-items": _ctx.breadcrumbs,
            "menu-items": _ctx.menuItems,
            "user-role": _ctx.userRole,
            "data-test": "header"
        }, {
            account: _withCtx(() => [
                _createVNode(_component_auth_popover, {
                    token: _ctx.token,
                    "dark-mode-options": {
                        attribute: 'data-theme',
                        valueDark: 'dark',
                    }
                }, null, 8, ["token"])
            ]),
            _: 1
        }, 8, ["config", "service-translations", "token", "account-id", "breadcrumb-items", "menu-items", "user-role"]),
        (_ctx.isErrorShown)
            ? (_openBlock(), _createBlock(_component_OrNotificationV3, {
                key: 0,
                class: "error-notification absolute z-[100] w-[calc(100%-(2*theme(spacing.3xl)))] m-3xl",
                variant: "error",
                onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.isErrorShown = false))
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.errorText), 1)
                ]),
                _: 1
            }))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
                id: "page-content",
                class: _normalizeClass(["page-content relative overflow-auto h-full w-full m-auto", { 'md:p-xl p-md': !_ctx.isFullPage, 'max-w-[1440px]': !_ctx.isFullPage }])
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 2)
        ]),
        _createVNode(_component_OrToastContainerV3)
    ]));
}
