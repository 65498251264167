import { h } from 'vue';
import { RouterView } from 'vue-router';
import i18n from '@/i18n';
import { useContactBooksV2 } from '@/stores';
import isContactsV1Enabled from '@/utils/isContactsV1Enabled';
import isContactsV2Enabled from '@/utils/isContactsV2Enabled';
import MigrationsLoader from '../components/MigrationsLoader.vue';
import RoutingTabsContainer from '../components/RoutingTabsContainer.vue';
import NotFound from '../views/NotFoundView.vue';
const routes = [
    {
        path: '/',
        component: MigrationsLoader,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                redirect: isContactsV2Enabled()
                    ? '/books'
                    : '/contact-books',
            },
            ...(isContactsV1Enabled() ? [{
                    path: '/contact-books',
                    name: 'contact books',
                    component: { render: () => h(RouterView) },
                    meta: {
                        breadcrumb: () => {
                            return {
                                label: 'Contact books',
                                to: '/contact-books',
                            };
                        },
                        title: function () {
                            return i18n.global.t('titles.contactBooks');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'books index',
                            component: () => import('../views/ContactBooksView.vue'),
                        },
                        {
                            path: '/contact-books/:bookName',
                            component: { render: () => h(RouterView) },
                            meta: {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                breadcrumb: function () {
                                    return {
                                        label: this.$route.params.bookName,
                                        to: `/contact-books/${this.$route.params.bookName}`,
                                    };
                                },
                                title: function () {
                                    return i18n.global.t('titles.contactBook', { bookName: this.params.bookName });
                                },
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'contacts',
                                    component: () => import('../views/ContactsView.vue'),
                                    props: true,
                                },
                                {
                                    path: '/contact-books/:bookName/access',
                                    name: 'contacts access',
                                    component: () => import('../views/AccessSettingsView.vue'),
                                    props: true,
                                    meta: {
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        breadcrumb: function () {
                                            return {
                                                label: 'Access settings',
                                            };
                                        },
                                        title: function () {
                                            return i18n.global.t('titles.accessSettings', { bookName: this.params.bookName });
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                }] : []),
            ...(isContactsV2Enabled() ? [{
                    path: '/books',
                    component: RoutingTabsContainer,
                    meta: {
                        breadcrumb: () => {
                            return {
                                label: 'Contact books',
                                to: '/books',
                            };
                        },
                        title: function () {
                            return i18n.global.t('titles.contactBooks');
                        },
                    },
                    children: [
                        {
                            path: '',
                            name: 'books v2 index',
                            component: () => import('../views/ContactBooksViewV2.vue'),
                        },
                        {
                            path: '/books/field-schemas',
                            name: 'field schemas',
                            component: () => import('../views/FieldSchemasView.vue'),
                            meta: {
                                title: function () {
                                    return i18n.global.t('titles.fields');
                                },
                            },
                        },
                        {
                            path: '/books/settings',
                            name: 'settings',
                            component: () => import('../views/SettingsView.vue'),
                            meta: {
                                title: function () {
                                    return i18n.global.t('titles.settings');
                                },
                            },
                        },
                        {
                            path: '/books/create',
                            name: 'create book v2',
                            component: () => import('../views/CreateContactBookView.vue'),
                            meta: {
                                breadcrumb: () => {
                                    return {
                                        label: 'Create a new book',
                                        to: '/books/create',
                                    };
                                },
                                title: function () {
                                    return i18n.global.t('titles.createContactBook');
                                },
                            },
                        },
                        {
                            path: '/books/contacts',
                            name: 'all contacts v2',
                            component: () => import('../views/ContactsViewV2.vue'),
                            meta: {
                                title: function () {
                                    return i18n.global.t('titles.allContacts');
                                },
                            },
                        },
                        {
                            path: '/books/:bookId/:createdContactsCount?/:failedContactsCount?',
                            component: { render: () => h(RouterView) },
                            meta: {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                breadcrumb: function () {
                                    var _a, _b;
                                    const bookId = this.$route.params.bookId;
                                    const { getBookById } = useContactBooksV2();
                                    return {
                                        label: (_b = (_a = getBookById(bookId)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : bookId,
                                        to: `/books/${this.$route.params.bookId}`,
                                    };
                                },
                                title: async function () {
                                    var _a, _b, _c;
                                    const bookId = this.params.bookId;
                                    const { getBookById, getContactBook } = useContactBooksV2();
                                    let bookName = (_a = getBookById(bookId)) === null || _a === void 0 ? void 0 : _a.label;
                                    if (!bookName) {
                                        await getContactBook(bookId);
                                        bookName = (_c = (_b = getBookById(bookId)) === null || _b === void 0 ? void 0 : _b.label) !== null && _c !== void 0 ? _c : bookId;
                                    }
                                    return i18n.global.t('titles.contactBook', { bookName });
                                },
                            },
                            children: [
                                {
                                    path: '',
                                    name: 'contacts v2',
                                    component: () => import('../views/ContactsViewV2.vue'),
                                    props: true,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: '/field-schemas',
                    redirect: {
                        name: 'field schemas',
                    },
                },
                {
                    path: '/settings',
                    redirect: {
                        name: 'settings',
                    },
                }] : [{
                    path: '/visibility-rules',
                    name: 'visibility rules',
                    component: () => import('../views/VisibilityRulesView.vue'),
                    meta: {
                        breadcrumb: () => {
                            return {
                                label: 'Visibility rules',
                                to: '/visibility-rules',
                            };
                        },
                        title: function () {
                            return i18n.global.t('titles.visibilityRules');
                        },
                    },
                }]),
            {
                path: '/changelog',
                name: 'changelog',
                meta: {
                    title: function () {
                        return i18n.global.t('titles.changelog');
                    },
                },
                component: () => import(/* webpackChunkName: 'changelog' */ '../views/ChangelogView.vue'),
            },
        ],
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        meta: {
            title: function () {
                return i18n.global.t('titles.notFound');
            },
        },
    },
];
export default routes;
