import { ColumnTypes } from '@onereach/types-contacts-api';
import { OrCheckboxV3 as OrCheckbox, OrSkeletonRectV3 as OrSkeletonRect } from '@onereach/ui-components';
import { useIntersectionObserver } from '@vueuse/core';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { defineComponent, ref } from 'vue';
import { ContactBooksOrderModes } from '@/constants';
import { useAuth } from '@/stores';
import { useContactsTableStoredValueV2 } from '@/use';
import { immutableSplice, getDefaultContactsTableStoredValueV2, } from '@/utils';
import ContactsTableColumnHeaderCell from './ContactsTableColumnHeaderCellV2.vue';
import ContactsTableDataCell from './ContactsTableDataCellV2';
const typesToOrder = [
    ColumnTypes.string,
    ColumnTypes.md,
    ColumnTypes.integer,
    ColumnTypes.double,
    ColumnTypes.date,
    ColumnTypes['array of strings'],
    undefined,
    null,
];
export default defineComponent({
    name: 'ContactsTable',
    components: {
        OrSkeletonRect,
        OrCheckbox,
        ContactsTableDataCell,
        ContactsTableColumnHeaderCell,
    },
    props: {
        bookId: {
            type: String,
            required: false,
            default: undefined,
        },
        contacts: {
            type: Array,
            required: false,
            default: () => [],
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
        selected: {
            type: Array,
            required: false,
            default: () => [],
        },
        singleSelected: {
            type: String,
            required: false,
            default: null,
        },
        columnToOrderBy: {
            type: String,
            required: false,
            default: 'created',
        },
        orderMode: {
            type: String,
            required: false,
            default: ContactBooksOrderModes.DESC,
        },
        rowsLimit: {
            type: Number,
            required: false,
            default: 20,
        },
        areContactsLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isBookLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
        isSearching: {
            type: Boolean,
            required: false,
            default: false,
        },
        isShowingHeaderOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCheckboxHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['select', 'view', 'order'],
    setup(props) {
        const checkboxCellIntersectionPartRef = ref();
        const authStore = useAuth();
        const authRefs = storeToRefs(authStore);
        const accountId = authRefs.accountId.value;
        const contactsTableStoredValue = useContactsTableStoredValueV2(props.bookId, accountId, props.fieldSchemas);
        const isShowingCheckboxesColumnShadow = ref(false);
        useIntersectionObserver(checkboxCellIntersectionPartRef, ([{ intersectionRatio }]) => {
            isShowingCheckboxesColumnShadow.value = intersectionRatio === 0;
        });
        return {
            checkboxCellIntersectionPartRef,
            typesToOrder,
            contactsTableStoredValue,
            isShowingCheckboxesColumnShadow,
        };
    },
    data() {
        return {
            resizingColumnKey: null,
        };
    },
    computed: {
        isLoading() {
            return this.areContactsLoading || this.isBookLoading;
        },
        isHeaderRowLoading() {
            return this.isBookLoading && this.fieldSchemas.length == 0;
        },
        areCheckboxesVisible() {
            return !this.isCheckboxHidden && !this.isHeaderRowLoading;
        },
        loadingColumnsKeys() {
            return this.isHeaderRowLoading ? _.range(12) : _.map(this.fieldSchemasToShow, fs => fs.id);
        },
        loadingColumnsWidths() {
            return _.fromPairs(_.map(this.loadingColumnsKeys, key => { var _a, _b; return [key, ((_b = (_a = this.storedColumnsInfo[key]) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : 160) + 'px']; }));
        },
        loadingRowsKeys() {
            return _.range(this.rowsLimit);
        },
        fieldSchemasToShow() {
            return [
                {
                    id: 'id',
                    label: 'Id',
                    type: ColumnTypes.string,
                    machine_name: '_id',
                },
                ...this.fieldSchemas,
                {
                    id: 'created_at',
                    label: 'Created at',
                    type: ColumnTypes.date,
                    machine_name: '_created_at',
                },
                {
                    id: 'updated_at',
                    label: 'Updated at',
                    type: ColumnTypes.date,
                    machine_name: '_updated_at',
                },
            ].filter(({ id }) => { var _a; return (_a = this.storedColumnsInfo[id]) === null || _a === void 0 ? void 0 : _a.isShown; });
        },
        storedColumnsInfo() {
            return this.contactsTableStoredValue.columns;
        },
        dataCellCss() {
            return _.mapValues(this.storedColumnsInfo, ({ width }) => ({
                width: width + 'px',
            }));
        },
        isEmpty() {
            return this.contacts.length === 0;
        },
        allItemsIds() {
            return this.contacts.map(({ id }) => id);
        },
        isAnySelected() {
            return this.selected.length > 0;
        },
        isAllSelected() {
            return !this.isEmpty && _.difference(this.allItemsIds, this.selected).length === 0;
        },
        isAnyResizing() {
            return this.resizingColumnKey !== null;
        },
        emptyTableText() {
            return this.isSearching ? this.$t('contacts.table.noMatchingRecords') : this.$t('contacts.table.empty');
        },
    },
    watch: {
        fieldSchemas: {
            handler(val) {
                const defaultStoredValue = getDefaultContactsTableStoredValueV2(val);
                _.defaults(this.contactsTableStoredValue.columns, defaultStoredValue.columns);
            },
            deep: true,
        },
    },
    methods: {
        handleSelectAll() {
            this.$emit('select', this.isAnySelected ? [] : _.union(this.allItemsIds, this.selected));
        },
        handleSelect(contactId) {
            const index = this.selected.indexOf(contactId);
            if (index > -1) {
                this.$emit('select', immutableSplice(this.selected, index, 1));
            }
            else {
                this.$emit('select', [...this.selected, contactId]);
            }
        },
        onColumnHeaderWidthChangeStart(columnKey) {
            this.resizingColumnKey = columnKey;
        },
        onColumnHeaderWidthChange(columnKey, newWidth) {
            this.storedColumnsInfo[columnKey].width = newWidth;
        },
        onColumnHeaderWidthChangeEnd() {
            this.resizingColumnKey = null;
        },
        onOrderChange(columnKey, newOrderMode) {
            this.$emit('order', columnKey, newOrderMode);
        },
    },
});
