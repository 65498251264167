import { OrButtonV3 as OrButton, OrIconV3 as OrIcon, OrPopoverV3 as OrPopover, OrCheckboxGroupV3 as OrCheckboxGroup, } from '@onereach/ui-components';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, ref, toRefs } from 'vue';
import i18n from '@/i18n';
import { useAuth } from '@/stores';
import { useContactsTableStoredValueV2 } from '@/use';
export default defineComponent({
    name: 'ContactsTableSettingsMenu',
    components: {
        OrButton,
        OrIcon,
        OrPopover,
        OrCheckboxGroup,
    },
    props: {
        bookId: {
            type: String,
            required: false,
            default: undefined,
        },
        fieldSchemas: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    setup(props) {
        const { fieldSchemas } = toRefs(props);
        const menuButtonRef = ref();
        const popoverRef = ref();
        const authStore = useAuth();
        const authRefs = storeToRefs(authStore);
        const accountId = authRefs.accountId.value;
        const storedValue = useContactsTableStoredValueV2(props.bookId, accountId, fieldSchemas.value);
        const columnsModel = computed(() => _.map(fieldSchemas.value, ({ id }) => {
            var _a;
            return id && ((_a = storedValue.value.columns[id]) === null || _a === void 0 ? void 0 : _a.isShown) ? id : null;
        }).filter(Boolean));
        const columnsOptions = computed(() => {
            return [
                {
                    label: i18n.global.t('contacts.tableSettingsMenu.allFields'),
                    children: _.map(fieldSchemas.value, (item) => {
                        return {
                            label: item.label,
                            value: item.id,
                        };
                    }),
                },
            ];
        });
        function onColumnsModelUpdate(value) {
            _.forEach(storedValue.value.columns, (column, key) => {
                _.set(column, 'isShown', _.includes(value, key));
            });
        }
        return {
            storedValue,
            menuButtonRef,
            columnsOptions,
            columnsModel,
            onColumnsModelUpdate,
            popoverRef,
        };
    },
});
