export var BatchProcessStatus;
(function (BatchProcessStatus) {
    BatchProcessStatus["pending"] = "pending";
    BatchProcessStatus["success"] = "success";
    BatchProcessStatus["failed"] = "failed";
})(BatchProcessStatus || (BatchProcessStatus = {}));
export var ColumnTypes;
(function (ColumnTypes) {
    ColumnTypes["string"] = "string";
    ColumnTypes["integer"] = "integer";
    ColumnTypes["double"] = "double";
    ColumnTypes["date"] = "date";
    ColumnTypes["md"] = "md";
    ColumnTypes["identifier object"] = "identifier object";
    ColumnTypes["array of identifiers"] = "array of identifiers";
    ColumnTypes["array of objects"] = "array of objects";
    ColumnTypes["array of strings"] = "array of strings";
})(ColumnTypes || (ColumnTypes = {}));
export var MigrationState;
(function (MigrationState) {
    MigrationState["InProgress"] = "InProgress";
    MigrationState["ToDo"] = "ToDo";
    MigrationState["Done"] = "Done";
})(MigrationState || (MigrationState = {}));
export var SharedBookStatus;
(function (SharedBookStatus) {
    SharedBookStatus["pending"] = "pending";
    SharedBookStatus["shared"] = "shared";
    SharedBookStatus["canceled"] = "canceled";
    SharedBookStatus["deletedFromTarget"] = "deletedFromTarget";
    SharedBookStatus["deletedFromSource"] = "deletedFromSource";
})(SharedBookStatus || (SharedBookStatus = {}));
export var DB_TARGET_VERSION = '1';
