import { OrLoaderV3 as OrLoader, LoaderVariant } from '@onereach/ui-components';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { MigrationsStatus } from '@/constants';
import useContactsBooksStoreV1 from '@/stores/contactsBooks/contactsBooksV1';
import useContactsBooksStoreV2 from '@/stores/contactsBooks/contactsBooksV2';
import { debouncePromise } from '@/utils/debouncePromise';
export default defineComponent({
    name: 'MigrationsLoader',
    components: {
        OrLoader,
    },
    setup() {
        const storeV1 = useContactsBooksStoreV1();
        const storeV2 = useContactsBooksStoreV2();
        const route = useRoute();
        const isV2 = computed(() => { var _a; return (_a = route.path) === null || _a === void 0 ? void 0 : _a.startsWith('/books'); });
        const { migrationsStatus: migrationsStatusV1, } = storeToRefs(storeV1);
        const { checkIfMigrationsNeeded: checkIfMigrationsNeededV1, migrateContactsTables: migrateContactsTablesV1, } = storeV1;
        const { migrationsStatus: migrationsStatusV2, } = storeToRefs(storeV2);
        const { checkIfMigrationsNeeded: checkIfMigrationsNeededV2, migrateContactsTables: migrateContactsTablesV2, } = storeV2;
        const migrationsStatus = computed(() => isV2.value ? migrationsStatusV2.value : migrationsStatusV1.value);
        watch([isV2, migrationsStatus], async ([newIsV2, newMigrationsStatus]) => {
            switch (newMigrationsStatus) {
                case MigrationsStatus.UNDEFINED: {
                    if (newIsV2) {
                        const migrate = async (retries = 0) => {
                            try {
                                await migrateContactsTablesV2();
                            }
                            catch (e) {
                                if (retries > 2) {
                                    throw e;
                                }
                                await debouncePromise(() => migrate(retries + 1), 3000);
                            }
                        };
                        await migrate();
                    }
                    else {
                        await checkIfMigrationsNeededV1();
                    }
                    break;
                }
                case MigrationsStatus.IN_PENDING: {
                    if (newIsV2) {
                        try {
                            // getMigrationState can fail if DB was removed
                            await checkIfMigrationsNeededV2();
                        }
                        catch (e) {
                            // so in the case of error running first migrateContacts
                            // it will launch lambda which will re-initialize DB and run migrations
                            await debouncePromise(async () => {
                                await migrateContactsTablesV2();
                                await checkIfMigrationsNeededV2();
                            }, 3000);
                        }
                    }
                    else {
                        await migrateContactsTablesV1();
                    }
                    break;
                }
                default:
                    return;
            }
        }, { immediate: true });
        return {
            MigrationsStatus,
            LoaderVariant,
            migrationsStatus,
        };
    },
    computed: {
        getDescription() {
            switch (this.migrationsStatus) {
                case MigrationsStatus.UNDEFINED:
                    return this.$t('contactBooks.pendingMigrationsStatus');
                case MigrationsStatus.IN_PENDING:
                    return this.$t('contactBooks.migratingContactsDesc');
                default:
                    return undefined;
            }
        },
    },
});
