import { defineComponent } from 'vue';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
export default defineComponent({
    name: 'NotFound',
    components: {
        OrButton,
    },
    data() {
        return {
            code: '404',
        };
    },
});
