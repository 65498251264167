import { OrModalV3 } from '@onereach/ui-components';
import { OrButtonV2 as OrButton } from '@onereach/ui-components-legacy';
import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import { useContactsTableStoredValue } from '@/use';
import { getCompositeColumnsMapping, getDefaultContactsTableStoredValue } from '@/utils';
import ContactsColumnsForm from '../ContactsColumnsForm';
export default defineComponent({
    name: 'ContactsTableSettingsModal',
    components: {
        OrModalV3,
        OrButton,
        ContactsColumnsForm,
    },
    props: {
        bookName: {
            type: String,
            required: false,
            default: () => null,
        },
        schema: {
            type: Object,
            required: false,
            default: () => null,
        },
        withCompositeColumns: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },
    emits: ['close'],
    expose: ['modalComponent'],
    setup(props) {
        const storedValue = useContactsTableStoredValue(props.bookName, props.schema, props.withCompositeColumns);
        const localState = ref(_.cloneDeep(storedValue.value));
        return {
            storedValue,
            localState: localState,
            modalComponent: ref(),
        };
    },
    computed: {
        columns() {
            return this.localState.columns;
        },
        arrayOfColumns: {
            get() {
                return _.toPairs(this.columns).map(([key, column]) => {
                    var _a, _b, _c, _d;
                    return ({
                        key,
                        label: (_d = (_b = (_a = this.schema[key]) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : (_c = getCompositeColumnsMapping()[key]) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : key,
                        value: column === null || column === void 0 ? void 0 : column.isShown,
                    });
                });
            },
            set(newColumns) {
                _.forEach(newColumns, ({ key, value }) => _.set(this.columns[key], 'isShown', value));
            },
        },
        isChanged() {
            return !_.isEqual(this.storedValue.columns, this.columns);
        },
    },
    watch: {
        schema: {
            handler: function (newVal) {
                if (!_.isEqual(_.sortBy(_.keys(newVal)), _.sortBy(_.keys(this.storedValue.columns)))) {
                    const defaultColumns = getDefaultContactsTableStoredValue(newVal, this.withCompositeColumns).columns;
                    this.storedValue.columns = _.pick(_.defaults(this.storedValue.columns, defaultColumns), _.keys(defaultColumns));
                }
            },
            deep: true,
        },
        isOpen: {
            handler: function (newVal) {
                if (newVal)
                    this.localState.columns = _.cloneDeep(this.storedValue.columns);
            },
        },
    },
    methods: {
        applyChanges() {
            _.forEach(this.storedValue.columns, (column, key) => { var _a; return _.set(column, 'isShown', (_a = this.columns[key]) === null || _a === void 0 ? void 0 : _a.isShown); });
            this.$emit('close');
        },
    },
});
