import { ref, reactive, watch } from 'vue';
import _ from 'lodash';
function useLocalState(state) {
    const isChanged = ref(false);
    const defaultState = reactive(state);
    const localState = reactive(_.cloneDeep(state));
    watch([defaultState, localState], (_before, [s, ls]) => {
        isChanged.value = !_.isEqual({ ...s }, { ...ls });
    });
    const reset = () => {
        Object.assign(localState, { ...defaultState });
    };
    const replace = (newState) => {
        Object.assign(defaultState, newState);
        Object.assign(localState, newState);
    };
    return {
        localState,
        isChanged,
        reset,
        replace,
    };
}
export default useLocalState;
