import { defineStore } from 'pinia';
import ContactsApi from '@/api/ContactsApiV2';
const useSettingsStore = defineStore('settings', {
    state: () => ({
        indexStatuses: [],
        contactsCount: 0,
        systemBusy: false,
        columnCount: 0,
    }),
    actions: {
        async getIndexStatuses() {
            this.indexStatuses = await ContactsApi.administrationApi.getIndicesStatus();
        },
        async getContactsCount() {
            this.contactsCount = await ContactsApi.administrationApi.getCountInTable('contact');
        },
        async getOperationInfo(columnName) {
            this.systemBusy = await ContactsApi.administrationApi.isSystemBusy();
            this.columnCount = await ContactsApi.administrationApi.getCountInTable(columnName);
        },
        async toggleIndex(index) {
            if (index.toBeRemoved && index.exists) {
                await ContactsApi.administrationApi.dropIndex(index);
            }
            else if (!index.exists && !index.toBeRemoved) {
                await ContactsApi.administrationApi.addIndex(index);
            }
        },
    },
});
export default useSettingsStore;
