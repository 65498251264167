import { OrIconButtonV3 as OrIconButton, OrMenuV3 as OrMenu, OrMenuItemV3 as OrMenuItem, } from '@onereach/ui-components';
import { defineComponent, ref } from 'vue';
import { ExportFormats } from '@/constants';
export default defineComponent({
    name: 'ContactsExportMenu',
    components: {
        OrIconButton,
        OrMenu,
        OrMenuItem,
    },
    props: {
        count: {
            type: Number,
            required: false,
            default: () => null,
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['export'],
    setup(_, { emit }) {
        const buttonRef = ref();
        const menuRef = ref();
        return {
            ExportFormats,
            buttonRef,
            menuRef,
            menuItems: [
                {
                    label: '.CSV',
                    handler: () => emit('export', ExportFormats.CSV),
                },
                {
                    label: '.JSON',
                    handler: () => emit('export', ExportFormats.JSON),
                },
            ],
        };
    },
});
