import isContactsV1Enabled from './isContactsV1Enabled';
import isContactsV2Enabled from './isContactsV2Enabled';
function getMenuItems($t) {
    if (!isContactsV1Enabled()) {
        return [];
    }
    return [
        ...(isContactsV2Enabled()
            ? [{
                    label: $t('menuItems.contactBooks'),
                    url: '/books',
                },
                {
                    label: $t('menuItems.oldContactBooks'),
                    url: '/contact-books',
                }]
            : [{
                    label: $t('menuItems.contactBooks'),
                    url: '/contact-books',
                },
                {
                    label: $t('menuItems.visibilityRules'),
                    url: '/visibility-rules',
                }]),
    ];
}
export default getMenuItems;
